import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import './ContactForm.css'; // Import the CSS file

const ContactForm = () => {
    const [isSent, setIsSent] = useState(false);
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_va8cuwn", // Replace with your service ID
                "template_z298ian", // Replace with your template ID
                e.target,
                "f7UbHRyDpeHYh8bRt" // Replace with your user ID
            )
            .then(
                (result) => {
                    document.getElementById("contact_form").reset();
                    setIsSent(true);
                    alert('Thank you for reaching out, I will get back to you as soon as possible');
                },
                (error) => {
                    console.error(error);
                    setIsSent(!isSent); // Toggle isSent state on error
                }
            );
    };

    return (
        <div className="contact-form">
            {/* Other elements in your form, if any */}

            <div className="form-container">
                <form
                    id="contact_form"
                    ref={form}
                    method="POST"
                    target="_blank"
                    onSubmit={sendEmail}
                    className="form"
                >
                    <div className="name-field">
                        <label htmlFor="firstname">Name</label>
                        <input
                            className="input-field"
                            placeholder="Enter your Name"
                            id="firstname"
                            type="text"
                            name="from_name"
                            required
                        />
                    </div>

                    <div className="email-field">
                        <label htmlFor="email">E-mail</label>
                        <input
                            className="input-field"
                            placeholder="Enter your E-mail"
                            id="email"
                            type="email"
                            name="from_email"
                            required
                        />
                    </div>

                    <div className="message-field">
                        <label>Message</label>
                        <textarea
                            className="input-field"
                            placeholder="Enter your message..."
                            name="message"
                            required
                        ></textarea>

                    </div>
                    <div className="submit-button-container contactinfo1">
                        <button type="submit" className="submit-button ">Send
                        </button>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default ContactForm;