import React from 'react';
import './Portfolio.css';
import headshot1 from '../headshot1.png';
import PSTpic1 from '../PST Mail Project1.png'
import Data from '../Data-Auto.png';
import Milestone from '../Milestone1.png'
import { m, LazyMotion, domAnimation } from "framer-motion";
import SectionTitle from "./SectionTitle.jsx";
import ContactForm from "./ContactForm";
import htmlIcon from '../html.svg';
import cssIcon from '../css.svg';
import tailwindIcon from '../tailwind.svg';
import reactIcon from '../react.svg';
import csharpIcon from '../csharp.svg';
import jsIcon from '../javascript.svg';
import figmaIcon from '../figma.svg';
import postgresqlIcon from '../postgresql.svg';
import githubIcon from '../github.svg';
import agileIcon from '../dotnet.svg';
import windowsFormIcon from '../windowsForm.svg';
import asposeEmailIcon from '../asposeEmail.svg';
import trelloIcon from '../trello.svg';
import Selenium from '../selenium.svg';
import Chrome from '../chrome.svg';
import pyton from '../python.svg';

function Portfolio() {
    function getSkillIcon(skill) {
        switch (skill) {
            case 'HTML':
                return htmlIcon;
            case 'Tailwind CSS':
                return tailwindIcon;
            case 'React':
                return reactIcon;
            case 'C#':
                return csharpIcon;
            case 'JavaScript':
                return jsIcon;
            case 'Python':
                return pyton;
            case 'Figma':
                return figmaIcon;
            case 'PostgreSQL':
                return postgresqlIcon;
            case 'Github':
                return githubIcon;
            case 'Framework':
                return agileIcon;
            case 'Windows Form':
                return windowsFormIcon;
            case 'Aspose Email':
                return asposeEmailIcon;
            case 'Trello':
                return trelloIcon;
            case 'Selenium':
                return Selenium;
            case 'Chrome Driver':
                return Chrome;
            default:
                return null;
        }
    }
    const projects = [
        
        {
            title: 'PST Mail File Extractor',
            description: (
                <>
                    <p>A Windows Form Application, that extracts information from PST Files and exports it to an Excel file.</p>
                    <p>Customizable extraction with the following filters:</p>
                    <ul>
                        <li>Name</li>
                        <li>Address</li>
                        <li>Subject</li>
                        <li>Body</li>
                        <li>Categories</li>
                        <li>Date & Time</li>
                    </ul>
                </>
            ),
            link: 'https://github.com/Bryan-Warner/EmailProject-Windows-App',
            image: PSTpic1,
            skills: ["C#", "Windows Form", "Aspose Email"],
            target: "_blank"
        },
        {

            title: 'Automating Content Editing Workflow',
            description: (
                <>
                    <p>C# application that automates browser interactions. Designed to clean and standardise data.</p>
                    <p>Key Features</p>
                    <ul>
                        <li>Clean And Categorise Music Data According To Required Format And Standards.</li>
                        <li>Simplifies User Required Review</li>
                        <li>Accounts for errors and natural breakpoints</li>

                    </ul>
                </>
            ),

            link: 'https://github.com/Bryan-Warner/Automating-Workflow-Project',
            image: Data,

            skills: ["C#", "Selenium", "Chrome Driver", "JavaScript"],
            target: "_blank"
        },
        {

            title: 'Milestone WebApp - In Development',
            description: (
                <>
                    <p>A project management web application for contract work.</p>
                    <p>Key Features</p>
                    <ul>
                        <li>Create a Project </li>
                        <li>Send Other Users Projects To Accept</li>
                        <li>Display Project Progress Through Completion of Project Milestones</li>
                        <li>Facilitate Payment Between Users</li>

                    </ul>
                </>
            ),

            link: '#',
            image: Milestone,

            skills: ["HTML", "Tailwind CSS", "Trello", "JavaScript", "Figma", "PostgreSQL"],
            target: "_blank"
        }

    ];
    const aboutMe = {
        name: 'Bryan',
        location: 'Brisbane, Australia',
        occupation: 'Software Engineer',
        description: (
            <p>
                Im a 26 year old Brisbane based software developer. I have a Bachelor of Information Technology, majoring in Computer Science from Queensland University of Technology. I continued expanding my software development skills through personal projects, an internship at Trade Service of Australia and freelance work for Dataannotation Tech. I enjoy working with others to develop technical solutions for challenging problems.
            </p>
        ),
        skills: ['JavaScript', 'React', 'HTML', 'CSS', 'C#'],
        workExperience: [
            {
                company: 'Trade Services of Australia',
                position: 'Software Engineering Intern',
                duration: '2023 - Present',
                description: 'Worked on developing features for their internal platform.',
            },

        ],
        education: {
            institution: 'Queensland University of Technology',
            degree: 'Bachelor of Information Technology (Major: Computer Science)',
            graduationYear: '2023',
        },
    };
    
    return (
        <div className="portfolio">
            <section id="about" className="bgimage-about sec-pad">
                <div className="container">

                    <div className="grid flex row">
                        <SectionTitle title="About Me" />
                        <div className="col-lg-4">

                            <img src={headshot1} class="imageAboutPage" alt="Bryan" />
                        </div>
                        <div className="col-lg-8">

                            <div className="aboutmeparagraph">
                                <p>
                                    {aboutMe.description}
                                </p>
                            </div>

                          
                            <div class="skills-container">
                                <h2>Technical Skills</h2>
                                <div class="skills">
                                    <div class="skills__skill">
                                        <img src={htmlIcon} alt="HTML" />
                                        HTML
                                    </div>
                                    <div class="skills__skill">
                                        <img src={cssIcon} alt="CSS" />
                                        CSS
                                    </div>
                                    <div class="skills__skill">
                                        <img src={tailwindIcon} alt="Tailwind CSS" />
                                        TailWind CSS 
                                    </div>
                                    <div class="skills__skill">
                                        <img src={reactIcon} alt="React" />
                                        React
                                    </div>
                                    <div class="skills__skill">
                                        <img src={csharpIcon} alt="C#" />
                                        C#
                                    </div>
                                    <div class="skills__skill">
                                        <img src={jsIcon} alt="JavaScript" />
                                        JavaScript
                                    </div>
                                    <div class="skills__skill">
                                        <img src={pyton} alt="Figma" />
                                        Python
                                    </div>
                                    <div class="skills__skill">
                                        <img src={postgresqlIcon} alt="PostgreSQL" />
                                        PostGreSQL
                                    </div>
                                    <div class="skills__skill">
                                        <img src={githubIcon} alt="Github" />
                                        Github
                                    </div>
                                    <div class="skills__skill">
                                        <img src={agileIcon} alt=".NET Framework" />
                                        Framework
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="projects" class="bgimage-about sec-pad">
                <div class="container">
                    <div className="">
                        <SectionTitle title="MY RECENT PROJECTS" />
                    </div>
                    <div className="">

                    </div>

                    <div class="grid flex">
                        {projects.map((project, index) => (

                            <div key={index} class="">
                                <div className="rem">
                                    <h1>{`PROJECT ${index + 1}`}</h1>
                                    <h2>{`${project.title}`}</h2>
                                </div>


                                <div className='project'>
                                
                                    <div className="overstyle">
                                        
                                        <img src={project.image} alt={project.title} />
                                    </div>
                                    <div className="proj-cen">
                                        <div class="">
                                            <div class="project-info">
                                                <h4>{project.title}</h4>
                                                <p>{project.description}</p>
                                                <h3 className="tooltitle">Tools Used</h3>
                                                <div class="skills1">
                                                    {project.skills &&
                                                        project.skills.map((skill, skillIndex) => (
                                                            <div key={skillIndex} className={`skills__skill1 ${skill === 'Tailwind CSS' || skill === 'PostgreSQL' ? 'larger-skill' : ''}`}>
                                                                <img
                                                                    src={getSkillIcon(skill)}
                                                                    alt={skill}
                                                                    className="skill-icon"
                                                                />
                                                                {skill}
                                                            </div>
                                                        ))}
                                                            </div>
                                                <div class="buttonforproj">
                                                    {index !== 2 && (
                                                        <a className="projbutton" href={project.link} target="_blank" rel="noopener noreferrer">
                                                            View Project
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                            
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section id="contactme" class="contact sec-pad sec-pad-bot">
                <div

                    className="container"
                >
                    <div className="cont">

                        <div className="cen">
                            <LazyMotion features={domAnimation} strict>
                                <m.div
                                    initial={{ x: 200 }}
                                    whileInView={{ x: 0 }}
                                    transition={{ duration: 0.6, type: "spring" }}
                                    className=""
                                >
                                    <div className="contactmetitle w-full">
                                        <SectionTitle title="CONTACT ME" />

                                    </div>
                                    <ContactForm />
                                </m.div>
                            </LazyMotion>
                        </div>
                    </div>

                </div>
            </section>

        </div>

    );
}

export default Portfolio;