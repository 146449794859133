import React from 'react';
import './Hero.css';
import gitpic from '../github-ico.png';
import linkedin from '../linkedin-ico.png'
function HeroSection() {
    const handleScrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            
            const startPosition = window.pageYOffset;
            const targetPosition = section.getBoundingClientRect().top + window.pageYOffset;
            const distance = targetPosition - startPosition;
            const duration = 800; // Adjust the duration as needed

            let start = null;
            const step = (timestamp) => {
                if (!start) start = timestamp;
                const progress = timestamp - start;
                window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration));
                if (progress < duration) {
                    requestAnimationFrame(step);
                }
            };
            requestAnimationFrame(step);
        }
    };
    const easeInOutCubic = (t, b, c, d) => {
        if ((t /= d / 2) < 1) return c / 2 * t * t * t + b;
        return c / 2 * ((t -= 2) * t * t + 2) + b;
    };


    return (

        <section className="bgimage" id="home">

            <div className="container-fluid bryhero">
                <div className="row heroresize">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 hero-text sec-pad-top">
                        <h2 className="hero_title">Hi, I'm Bryan</h2>
                        <p className="hero_desc">I'm a software engineer, who enjoys problem solving <br />and developing meaningful solutions with code</p>

                        <div className="home-hero__cta">

                        </div>
                    </div>

                </div>
                <div className="home-hero__socials">
                    <div className="home-hero__social">
                        <a href="https://www.linkedin.com/in/bryan-warner" className="home-hero__social-icon-link" target="_blank" rel="noopener noreferrer">
                            <img
                                src={linkedin}
                                alt="icon"
                                className="home-hero__social-icon"
                            />
                        </a>
                    </div>
                    <div className="home-hero__social">
                        <a href="https://github.com/Bryan-Warner" className="home-hero__social-icon-link" target="_blank" rel="noopener noreferrer">
                            <img
                                src={gitpic}
                                alt="icon"
                                className="home-hero__social-icon"
                            />
                        </a>
                    </div>
                </div>

                <div className="home-hero__mouse-scroll-cont" onClick={() => {
                    handleScrollToSection('about'); // Update sectionId for About Me
                    
                }}>
                    <div className="mouse"></div>

                </div>
            </div>
        </section>
    );
}

export default HeroSection;