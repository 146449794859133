import React from 'react';
import './Footer.css';
import gitpic from '../github-ico.png';
import linkedin from '../linkedin-ico.png';
function Footer() {
    return (
        <footer>
            <div class="main-footer__row main-footer__row-1">
                <div className='main-footer'>

                    <div class="home-hero__social-foot">

                        <a href="https://www.linkedin.com/in/bryan-warner" class="home-hero__social-icon-link" target="_blank" rel="noopener noreferrer">
                            <img
                                src={linkedin}
                                alt="icon"
                                class="home-hero__social-icon"
                            />
                        </a>
                        <a href="https://github.com/Bryan-Warner" class="home-hero__social-icon-link" target="_blank" rel="noopener noreferrer">
                            <img
                                src={gitpic}
                                alt="icon"
                                class="home-hero__social-icon"
                            />
                        </a>

                    </div>

                    <div className='contactinfo'>
                        <span>© 2024 Bryan Warner. All rights reserved</span>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;