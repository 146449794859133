import { m, LazyMotion, domAnimation } from "framer-motion";
import './SectionTitle.css'; // Import the CSS file

const SectionTitle = (props) => {
    const { title, subtitle } = props;

    return (
        <LazyMotion features={domAnimation} strict>
            <m.div className="SectionTitle"> {/* Use the base class from CSS */}
                <span className="subtitle"> {/* Use class from CSS */}
                    {subtitle}
                </span>
                <h2 className="title"> {/* Use class from CSS */}
                    {title.split("").map((char, index) => {
                        if (char === " ") {
                            return " ";
                        }
                        return <span key={index} className="bounce">{char}</span>; // Use class from CSS
                    })}
                </h2>
            </m.div>
        </LazyMotion>
    );
};

export default SectionTitle;