import React from 'react';
import Portfolio from './Portfolio';
import HeroSection from './Hero';

function Home() {
    return (
        <div className="home-container">
            <HeroSection />
            <Portfolio />
        </div>
    );
}

export default Home;