import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import CV from '../Bryan Warner CV.pdf'

const NavBar = () => {
    const [scrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleScrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            setIsMenuOpen(false);
            const startPosition = window.pageYOffset;
            const targetPosition = section.getBoundingClientRect().top + window.pageYOffset;
            const distance = targetPosition - startPosition;
            const duration = 800; // Adjust the duration as needed

            let start = null;
            const step = (timestamp) => {
                if (!start) start = timestamp;
                const progress = timestamp - start;
                window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration));
                if (progress < duration) {
                    requestAnimationFrame(step);
                }
            };
            requestAnimationFrame(step);
        }
    };
    const easeInOutCubic = (t, b, c, d) => {
        if ((t /= d / 2) < 1) return c / 2 * t * t * t + b;
        return c / 2 * ((t -= 2) * t * t + 2) + b;
    };
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav id="navbar" className={`navbar navbar-expand-lg ${scrolled ? 'scrolled' : ''}`}>
            <div className="container-fluid">
                <a href={CV} className="navbar-brand" download>
                    Download CV
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    aria-label="Toggle navigation"
                    aria-expanded={isMenuOpen ? 'true' : 'false'}
                    onClick={toggleMenu}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNavAltMarkup">
                    <div className="navbar-nav ms-auto">
                        <NavLink
                            to="/"
                            className="nav-link"
                            onClick={() => handleScrollToSection('home')}
                        >
                            Home
                        </NavLink>
                        <NavLink
                            to="/"
                            className="nav-link"
                            onClick={() => handleScrollToSection('about')}
                        >
                            About
                        </NavLink>
                        <NavLink
                            to="/"
                            className="nav-link"
                            onClick={() => handleScrollToSection('projects')}
                        >
                            Projects
                        </NavLink>
                        <NavLink
                            to="/"
                            className="nav-link"
                            onClick={() => handleScrollToSection('contactme')}
                        >
                            Contact
                        </NavLink>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
